import { useEffect, useState, useContext } from 'react';
import MaterialTable from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AppRunTimeContext } from '../../../Context/index';
import { AddSiteModal } from './AddSiteModal';
import { protectedResources } from '../../../config/authConfig';
import { axiosInstance as axios } from '../../../utils/axiosInstance';
import { tableIcons } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTableCell-alignCenter': {
            border: '1px dotted rgba(0,0,0,0.2)'
        },
        '& .MuiTableCell-root': {
            padding: '5px'
        }
    },
    textField: {
        [theme.breakpoints.down('xs')]: {},
        float: 'right',
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5)
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    }
}));

export const SiteTable = (props) => {
    const classes = useStyles();
    const [siteData, setiteData] = useState([]);
    const [open, setOpen] = useState(false);
    const { g_siteData, g_setSiteData, g_appRunTimeData, g_areSitesFetched, g_SetAppRunTimeData } =
        useContext(AppRunTimeContext);

    const columns = [
        {
            field: 'location',
            align: 'center',
            title: 'Site',
            defaultSort: 'asc',
            editable: false,
            filterPlaceholder: 'Site'
        },
        {
            field: 'masterLocation',
            align: 'center',
            title: 'Master Site',
            lookup: g_appRunTimeData.distinctMasterLocations
                .sort((a, b) => a - b)
                .reduce((a, v) => ({ ...a, [v]: v }), {}),
            filterPlaceholder: 'Master Site'
        },
        {
            field: 'description',
            align: 'center',
            title: 'Description',
            filterPlaceholder: 'Description'
        },
        {
            field: 'isMaster',
            align: 'center',
            title: 'Is this Master Site',
            render: (rowData) => {
                const isChecked = rowData.isMaster === 'Y';
                return <input type="checkbox" disabled defaultChecked={isChecked}></input>;
            },
            lookup: { Y: 'Yes', N: 'No' },
            filterPlaceholder: 'Master Site only'
        }
    ];

    const deleteSite = async (site) => {
        if (!confirm('This will delete the assignment, do you wish to continue?')) return;
        let APIResponse = await axios.delete(protectedResources.apiDeleteSite.endpoint, {
            data: { location: site }
        });
        if (APIResponse.status === 200 && APIResponse.statusText === 'OK') {
            props.deleteSite(site);
        } else alert(responseData.error);

        //Remove from in memory locations array
        const newArr = g_appRunTimeData.distinctMasterLocations.filter((item) => item !== site);
        g_SetAppRunTimeData({ ...g_appRunTimeData, distinctMasterLocations: newArr });
    };

    const ParentAddSite = (location, masterLocation, description, isMaster) => {
        if (g_siteData)
            g_setSiteData([...g_siteData, { location, masterLocation, description, isMaster }]);
        else g_setSiteData([{ location, masterLocation, description, isMaster }]);
        if (isMaster) {
            const newMasterLocArr = [...g_appRunTimeData.distinctMasterLocations, location];
            g_SetAppRunTimeData({ ...g_appRunTimeData, distinctMasterLocations: newMasterLocArr });
        }
    };

    const handleRowUpdate = (newData, oldData, resolve) => {
        axios
            .put(protectedResources.apiUpdateSite.endpoint, {
                location: newData.location,
                masterLocation: newData.masterLocation,
                description: newData.description,
                isMaster: newData.isMaster
            })
            .then((resp) => {
                oldData.masterLocation = newData.masterLocation;
                oldData.description = newData.description;
                oldData.isMaster = newData.isMaster;
                resolve();
            })
            .catch((err) => {
                resolve();
            });
    };

    useEffect(() => {
        if (g_siteData) setiteData(g_siteData);
        return () => {};
    }, [g_siteData]);

    return (
        <>
            <div className={classes.root}>
                {!g_areSitesFetched && <LinearProgress style={{ marginTop: '200px' }} />}
                {Boolean(g_areSitesFetched) && (
                    <MaterialTable
                        icons={tableIcons}
                        title=""
                        columns={columns}
                        data={siteData}
                        options={{
                            headerStyle: {
                                backgroundColor: '#8DC8E8',
                                color: '#000',
                                borderLeft: '1px solid #f0f0f0',
                                borderRight: '1px solid #f0f0f0'
                            },
                            filtering: true,
                            sorting: true,
                            search: true,
                            exportButton: true,
                            exportAllData: true,
                            pageSizeOptions: [5, 10, 20, 50, 100, siteData.length].sort(
                                (a, b) => a - b
                            ),
                            pageSize: 20
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    handleRowUpdate(newData, oldData, resolve);
                                })
                        }}
                        actions={[
                            {
                                icon: DeleteIcon,
                                tooltip: 'Delete',
                                onClick: (event, rowData) => deleteSite(rowData.location)
                            },
                            {
                                icon: AddBox,
                                tooltip: 'Add Site',
                                position: 'toolbar',
                                onClick: (event, rowData) => {
                                    setOpen(true);
                                }
                            }
                        ]}
                    />
                )}
            </div>
            <AddSiteModal open={open} setOpen={setOpen} AddSite={ParentAddSite} />
        </>
    );
};
