import React, { useContext } from 'react';
import moment from 'moment';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AppRunTimeContext } from '../../../Context/index';
const HISTOGRAM_TIME_FORMAT = 'DD/MM HH:mm';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

//Returns the extra minutes that passed 30min or 60min slot
const getLast30MinSlot = (time) => {
    return parseInt(time.format('HH:mm').substring(3)) > 30
        ? time.format('HH:mm').substring(3) - 30
        : time.format('HH:mm').substring(3);
};

const getMaxCount = (dayCount, nightCount) => {
    let max = 0;
    for (let i = 0; i < dayCount.length; ++i) {
        if (!isNaN(dayCount[i])) max = Math.max(max, dayCount[i]);
        if (!isNaN(nightCount[i])) max = Math.max(max, nightCount[i]);
    }
    return max;
};

//Returns all the labels for Histogram starting from current time
const getLabels = () => {
    const slotInterval = 30;
    let openTime = moment();
    let closeTime = moment().add(12, 'hours');
    openTime = openTime.subtract(getLast30MinSlot(openTime), 'minutes');
    closeTime = closeTime.subtract(getLast30MinSlot(closeTime), 'minutes');

    const labels = [];
    const startTime = moment(openTime, 'HH:mm');
    const endTime = moment(closeTime, 'HH:mm');

    while (startTime < endTime) {
        labels.push(startTime.format(HISTOGRAM_TIME_FORMAT));
        startTime.add(slotInterval, 'minutes');
    }

    return labels;
};

//Formats data for Histogram
const getData = (data, locationMap, userAssignedSites) => {
    if (!data) return {};
    let dayCount = {};
    let nightCount = {};
    let dayVolume = {};
    let nightVolume = {};
    const labels = getLabels();
    const filterMasterSite = localStorage.getItem('ETASearchLocation')
        ? JSON.parse(localStorage.getItem('ETASearchLocation')).masterLocation
        : null;

    for (const label of labels) {
        dayCount[label] = 0;
        nightCount[label] = 0;
        dayVolume[label] = 0;
        nightVolume[label] = 0;
    }

    for (const row of data) {
        //Only show rows where locationTo is the search site or its child or
        //its not a filtered site but is a user preferred site
        if (
            (filterMasterSite && filterMasterSite != locationMap.get(row.locationTo)) ||
            (!filterMasterSite && userAssignedSites.size && !userAssignedSites.get(row.locationTo))
        )
            continue;

        let time;
        if (row.actualTimeDelivery)
            time = moment(row.actualTimeDelivery.substr(0, 16), 'YYYY-MM-DDTHH:mm');
        else if (row.liveTimeDelivery)
            time = moment(row.liveTimeDelivery.substr(0, 16), 'YYYY-MM-DDTHH:mm');
        else if (row.plannedTimeDelivery)
            time = moment(row.plannedTimeDelivery.substr(0, 16), 'YYYY-MM-DDTHH:mm');

        const timeSlot = getLast30MinSlot(time);
        time = time.subtract(timeSlot, 'minutes');

        if (row.shift == 'Day') {
            dayCount[time.format(HISTOGRAM_TIME_FORMAT)]++;
            dayVolume[time.format(HISTOGRAM_TIME_FORMAT)] += row.volume;
        } else {
            nightCount[time.format(HISTOGRAM_TIME_FORMAT)]++;
            nightVolume[time.format(HISTOGRAM_TIME_FORMAT)] += row.volume;
        }
    }

    dayCount = Object.keys(dayCount).map((k) => dayCount[k]);
    nightCount = Object.keys(nightCount).map((k) => nightCount[k]);
    dayVolume = Object.keys(dayVolume).map((k) => dayVolume[k]);
    nightVolume = Object.keys(nightVolume).map((k) => nightVolume[k]);

    return { dayCount, nightCount, dayVolume, nightVolume };
};

export const Histogram = ({ tankerData }) => {
    const { g_appRunTimeData } = useContext(AppRunTimeContext);
    const locationMap = g_appRunTimeData.masterLocationHierarchy;
    const userAssignedSites = g_appRunTimeData.userAssignedSiteMap;
    const labels = getLabels();
    const { dayCount, nightCount, dayVolume, nightVolume } = getData(
        tankerData,
        locationMap,
        userAssignedSites
    );
    const maxTankers = getMaxCount(dayCount, nightCount);

    //I could not find a way to hide volumes from both Legend and histogram but show on tooltip.
    //So, I'd made volumes transparent, hard coded y-axis indexes, stacked volumes on top of shift.
    //With this I was able to achieve scaled chart with 50% width for 30min slot for each D/N shift
    const data = {
        labels,
        datasets: [
            {
                label: 'Day',
                data: dayCount,
                backgroundColor: 'rgba(255, 199, 32, 0.6)',
                stack: 'stack 0'
            },
            {
                label: 'Night',
                data: nightCount,
                backgroundColor: 'rgba(43, 92, 255, 0.6)',
                stack: 'stack 1'
            },
            {
                label: 'Day Volume',
                data: dayVolume,
                backgroundColor: 'transparent',
                stack: 'stack 0'
            },
            {
                label: 'Night Volume',
                data: nightVolume,
                backgroundColor: 'transparent',
                stack: 'stack 1'
            }
        ]
    };

    return (
        <Bar
            options={{
                aspectRatio: 5,
                responsive: true,
                plugins: {
                    legend: { display: false },
                    title: {
                        display: false,
                        text: 'Tanker Arrivals'
                    }
                },
                layout: { padding: 5 },
                scales: {
                    y: {
                        min: 0,
                        max: maxTankers + 2,
                        stacked: true
                    }
                }
            }}
            data={data}
            style={{ margin: '25px 0px' }}
        />
    );
};
