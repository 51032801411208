import useMediaQuery from '@material-ui/core/useMediaQuery';

// Checks if screen resolution is a mobile and returns boolean
export function isMobile() {
    const matches = useMediaQuery('(max-width:768px)');
    return matches;
}

//Returns Map of Location Hierarchy where key in master site and values are child sites
export const getLocationHierarchy = (records) => {
    let hierarchy = new Map();
    for (let i = 0; i < records.length; i++)
        hierarchy.set(
            records[i].location,
            records[i].masterLocation ? records[i].masterLocation : records[i].location
        );
    return hierarchy;
};

//Returns Map of Location description where key is a site code and value is the description
export const getLocationDescription = (records) => {
    let descriptionMap = new Map();
    for (let i = 0; i < records.length; i++)
        descriptionMap.set(records[i].location, records[i].description);
    return descriptionMap;
};

//Returns Map of user assigned sites where key is site code
export const getUserAssignedSites = (records) => {
    let siteMap = new Map();
    for (let i = 0; i < records.length; i++) siteMap.set(records[i].location, 1);
    return siteMap;
};
