import axios from 'axios';
import { acquireAccessToken } from '../index';
import { msalInstance } from '../index';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'content-type': 'application/json'
    },
    responseType: 'json'
});

axiosInstance.interceptors.request.use(async (config) => {
    const accessToken = await acquireAccessToken(msalInstance);
    config.headers.authorization = accessToken ? `Bearer ${accessToken}` : '';
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const successResponses = [
            '200',
            '201',
            '202',
            '203',
            '204',
            '205',
            '206',
            '207',
            '208',
            '226'
        ];
        if (error.response && error.response.status == '404')
            return Promise.resolve(error.response);
        if (error.response && !successResponses.includes(error.response.status)) {
            if (error.response.data && error.response.data.error) {
                alert(error.response.data.error);
            } else {
                alert(
                    `${error.response.statusText}, backend responded with http ${error.response.status}`
                );
            }
            return Promise.reject(error.response);
        } else if (error.response && error.response.data)
            return Promise.reject(error.response.data);
        return Promise.reject(error.message);
    }
);
