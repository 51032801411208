import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppRunTimeContext } from '../Context';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { g_userRoles } = useContext(AppRunTimeContext);
    const isValid = rest.roles.filter((role) => g_userRoles.includes(role)).length;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isValid) return <Component {...rest} {...props} />;
                return <Redirect to={'/'} />;
            }}
        />
    );
};
