import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PageLayout } from './components/PageLayout';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Home } from './pages';
import { TankerData } from './pages/TankerData/';
import { AdminPanel } from './pages/AdminPanel/';
import { ManageSite } from './pages/ManageSite/';
import { pageNotFound } from './pages/Page404/';
import { pageConf } from './config/pageConf';

// import "./styles/App.css"; Not using for now

const Pages = () => {
    return (
        <Switch>
            <Route
                path="/"
                component={Home}
                roles={pageConf.Home.roles}
                title={pageConf.Home.title}
                exact
            />
            <ProtectedRoute
                path="/data"
                component={TankerData}
                roles={pageConf.TankerData.roles}
                title={pageConf.TankerData.title}
                exact
            />
            <ProtectedRoute
                path="/manage/user"
                component={AdminPanel}
                roles={pageConf.AdminPanel.roles}
                title={pageConf.AdminPanel.title}
                exact
            />
            <ProtectedRoute
                path="/manage/site"
                component={ManageSite}
                roles={pageConf.ManageSites.roles}
                title={pageConf.ManageSites.title}
                exact
            />
            <Route
                path="/error"
                component={pageNotFound}
                roles={pageConf.Page404.roles}
                title={pageConf.Page404.title}
            />
            <ProtectedRoute
                path="*"
                component={pageNotFound}
                roles={pageConf.Page404.roles}
                title={pageConf.Page404.title}
            />
        </Switch>
    );
};

const App = ({ instance }) => {
    return (
        <Router>
            <MsalProvider instance={instance}>
                <PageLayout>
                    <Pages />
                </PageLayout>
            </MsalProvider>
        </Router>
    );
};

export default App;
