import { appRoles } from './appRoles';
import { pageConf } from './pageConf';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

export const loginRequest = {
    scopes: [process.env.REACT_APP_API_SCOPES]
};

export const protectedResources = {
    apiTankerData: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/tanker/data'
    },
    apiTankerTotals: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/tanker/totals'
    },
    apiUserData: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/roles'
    },
    apiGetAllUsers: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/all'
    },
    apiGetFilteredUsers: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/get?'
    },
    apiGetUserAssociatedSites: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/sites'
    },
    apiGetAllSites: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/site/all'
    },
    apiGetMasterSites: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/site/master'
    },
    apiAddNewSite: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/site/add'
    },
    apiDeleteSite: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/site/delete'
    },
    apiUpdateSite: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/site/update'
    },
    apiPostNewUser: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/add'
    },
    apiDeleteUser: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/delete'
    },
    apiUpdateUser: {
        endpoint: process.env.REACT_APP_API_BASE_URL + '/user/edit'
    }
};

export const UIGuardMappings = {
    navbar: {
        tankerData: pageConf.Home.roles,
        userManagement: pageConf.AdminPanel.roles,
        siteManagement: pageConf.ManageSites.roles
    }
};
