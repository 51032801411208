import { useState, useContext } from 'react';
import { axiosInstance as axios } from '../../../utils/axiosInstance';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { AppRunTimeContext } from '../../../Context/index';
import { protectedResources } from '../../../config/authConfig';

const useStyles = makeStyles((theme) => ({
    searchfield: {
        alignItems: 'center',
        verticalAlign: 'middle',
        margin: '0px 10px'
    }
}));

const customStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999, width: '200px' }),
    control: (e) => ({
        ...e,
        width: 200,
        marginTop: '10px',
        border: 'none',
        borderBottom: '1px solid grey'
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
};

export const AdminDataFilters = ({ setAllUserData, setIsFetchingData }) => {
    const classes = useStyles();
    const [user, setSearchUser] = useState('');
    const [site, setSearchSite] = useState('');
    const { g_appRunTimeData } = useContext(AppRunTimeContext);

    const options = g_appRunTimeData.distinctMasterLocations.sort().map((item) => {
        return { value: item, label: item };
    });
    options.unshift({ value: '', label: 'Location' });

    const searchUser = async () => {
        setIsFetchingData(true);
        const url =
            protectedResources.apiGetFilteredUsers.endpoint + 'user=' + user + '&site=' + site;

        const APIResponse = await axios.get(url);
        setIsFetchingData(false);
        if (APIResponse.status === 200 && APIResponse.statusText === 'OK')
            setAllUserData(APIResponse.data.records);
        else alert(APIResponse.data.error);
    };

    return (
        <>
            <TextField
                className={classes.searchfield}
                id="username"
                onChange={(e) => setSearchUser(e.target.value)}
                label="User Email"
                value={user}
            />
            <Select
                options={options}
                placeholder="Location"
                onChange={(e) => setSearchSite(e.value)}
                styles={customStyles}
            />
            <Button className={classes.searchfield} variant="contained" onClick={searchUser}>
                Search
            </Button>
            <br />
        </>
    );
};
