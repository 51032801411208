import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import { AppRunTimeContext } from '../Context';
import { isMobile } from '../utils/utils';
import { UIGuardMappings } from '../config/authConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
        flexGrow: 1,
        backgroundColor: '#002D4E',
        '& .MuiTypography-root': {
            fontFamily: 'Recoleta',
            fontSize: '22px'
        }
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
        textDecoration: 'none',
        color: 'inherit'
    },
    settingCog: {
        flexGrow: 1,
        fontSize: 35,
        color: '#ffffff'
    },
    rightNavItems: {
        float: 'right',
        margin: '0px 10px'
    },
    centerNavItems: {
        textAlign: 'center',
        alignItems: 'center',
        direction: 'row'
    },
    FonterraLogo: {
        height: '75px'
    },
    AppLogo: {
        height: '75px',
        margin: '0px 15px'
    }
}));

export const NavigationBar = () => {
    const { instance } = useMsal();
    const { g_userRoles } = useContext(AppRunTimeContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        return () => {};
    }, [instance, g_userRoles]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const desktopNav = (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Grid item xs={3}>
                        <img className={classes.FonterraLogo} src="./fonterraLogo.png" />
                    </Grid>
                    <Grid
                        item
                        xs={9}
                        container
                        justifyContent="center"
                        className={classes.centerNavItems}
                    >
                        <img className={classes.AppLogo} src="./appLogo.png" />
                        <Typography color="inherit" variant="h6">
                            Tanker ETA
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <AuthenticatedTemplate>
                            {Boolean(
                                UIGuardMappings.navbar.userManagement.filter((role) =>
                                    g_userRoles.includes(role)
                                ).length !== 0
                            ) && (
                                <Link
                                    className={classes.rightNavItems}
                                    color="inherit"
                                    to="/manage/user"
                                >
                                    <SettingsIcon className={classes.settingCog} />
                                </Link>
                            )}
                            {Boolean(
                                UIGuardMappings.navbar.siteManagement.filter((role) =>
                                    g_userRoles.includes(role)
                                ).length !== 0
                            ) && (
                                <Link
                                    className={classes.rightNavItems}
                                    color="inherit"
                                    to="/manage/site"
                                >
                                    <LocationOnIcon className={classes.settingCog} />
                                </Link>
                            )}
                            {Boolean(
                                UIGuardMappings.navbar.siteManagement.filter((role) =>
                                    g_userRoles.includes(role)
                                ).length !== 0
                            ) && (
                                <Button
                                    className={classes.rightNavItems}
                                    color="inherit"
                                    component={Link}
                                    to="/data"
                                >
                                    Tanker Data
                                </Button>
                            )}
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate></UnauthenticatedTemplate>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );

    const mobileNav = (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Grid item xs={2}>
                        <img
                            component={Link}
                            to="/"
                            className={classes.FonterraLogo}
                            src="./fonterraLogo.png"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        container
                        justifyContent="center"
                        className={classes.centerNavItems}
                    >
                        <img className={classes.AppLogo} src="./appLogo.png" />
                        <Typography color="inherit" variant="h6">
                            Tanker ETA
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <AuthenticatedTemplate>
                            <Button
                                onClick={handleClick}
                                id="simple-menu"
                                className={classes.rightNavItems}
                                color="inherit"
                            >
                                <MenuIcon />
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {Boolean(
                                    UIGuardMappings.navbar.siteManagement.filter((role) =>
                                        g_userRoles.includes(role)
                                    ).length !== 0
                                ) && (
                                    <MenuItem
                                        color="inherit"
                                        onClick={handleClose}
                                        component={Link}
                                        to="/data"
                                    >
                                        Tanker Data
                                    </MenuItem>
                                )}
                                {Boolean(
                                    UIGuardMappings.navbar.userManagement.filter((role) =>
                                        g_userRoles.includes(role)
                                    ).length !== 0
                                ) && (
                                    <MenuItem
                                        color="inherit"
                                        onClick={handleClose}
                                        component={Link}
                                        to="/manage/user"
                                    >
                                        Manage User
                                    </MenuItem>
                                )}
                                {Boolean(
                                    UIGuardMappings.navbar.siteManagement.filter((role) =>
                                        g_userRoles.includes(role)
                                    ).length !== 0
                                ) && (
                                    <MenuItem
                                        color="inherit"
                                        onClick={handleClose}
                                        component={Link}
                                        to="/manage/site"
                                    >
                                        Manage Site
                                    </MenuItem>
                                )}
                            </Menu>
                        </AuthenticatedTemplate>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );

    return <>{isMobile() ? mobileNav : desktopNav}</>;
};
