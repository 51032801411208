import { useEffect, useState, useContext } from 'react';
import { MsalAuthenticationTemplate, useMsal, useAccount } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { axiosInstance as axios } from '../../utils/axiosInstance';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { loginRequest, protectedResources } from '../../config/authConfig';
import { TankerDataGrid } from './components/TankerDataGrid';
import { TankerDataFilters } from './components/TankerDataFilters';
import { AppRunTimeContext } from '../../Context/index';
import { Histogram } from './components/Histogram';
import { TotalsTable } from './components/TotalsTable';
import { Collapsible } from '../../components/Collapsible';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ButtonGroup from '@material-ui/core/ButtonGroup';

export const TankerData = (props) => {
    document.title = props.title;
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tankerData, setTankerData] = useState(null);
    const [tankerTotals, setTankerTotals] = useState(null);
    const [isExpanded, setIsExpanded] = useState('expanded');
    const { g_appRunTimeData, g_SetAppRunTimeData } = useContext(AppRunTimeContext);
    const [isFetchingData, setIsFetchingData] = useState(false);

    useEffect(async () => {
        if (account && inProgress === 'none' && !tankerData) {
            const APIResponse = await axios.get(protectedResources.apiTankerData.endpoint);
            const totalAPIData = await axios.get(protectedResources.apiTankerTotals.endpoint);
            if (totalAPIData.status === 200 && totalAPIData.statusText === 'OK')
                setTankerTotals(totalAPIData.data.records);
            if (APIResponse.status === 200 && APIResponse.statusText === 'OK')
                setTankerData(APIResponse.data.records);
            else if (APIResponse.status === 404) {
                props.history.push({
                    pathname: '/error',
                    state: { error: APIResponse.data.error }
                });
            }
        }
        return () => {};
    }, [account, inProgress, instance]);

    //Reset the associated count variable to original value
    useEffect(() => {
        return () => {
            g_SetAppRunTimeData({
                ...g_appRunTimeData,
                associatedSiteCount: g_appRunTimeData.associatedSiteCountOriginal
            });
        };
    }, []);

    const handleExpansion = (newState) => {
        setIsExpanded(newState);
        //Reset to a blank state so if a user clicks the
        //same button again, it performs a update action
        setTimeout(() => {
            setIsExpanded('blank');
        }, 200);
    };

    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            <Grid container direction="row">
                <Grid item xs={false} sm={1}>
                    {' '}
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Grid>
                        <TankerDataFilters
                            setTankerData={setTankerData}
                            setIsFetchingData={setIsFetchingData}
                            setTankerTotals={setTankerTotals}
                        />
                        {Boolean(g_appRunTimeData.associatedSiteCount) && (
                            <ButtonGroup
                                style={{ float: 'right' }}
                                color="primary"
                                aria-label="outlined primary button group"
                            >
                                <Button
                                    style={{ padding: '10px 0px' }}
                                    onClick={() => handleExpansion('expanded')}
                                >
                                    <KeyboardArrowDownIcon />
                                </Button>
                                <Button
                                    style={{ padding: '10px 0px' }}
                                    onClick={() => handleExpansion('collapsed')}
                                >
                                    <KeyboardArrowUpIcon />
                                </Button>
                            </ButtonGroup>
                        )}
                    </Grid>
                    <br />
                    <br />
                    {(isFetchingData || !tankerData) && <LinearProgress />}
                    {/* Display the collapsibles only when user is assigned to a site.*/}
                    {tankerData && Boolean(g_appRunTimeData.associatedSiteCount) && (
                        <>
                            <Collapsible name="Tanker Arrival Histogram " isExpanded={isExpanded}>
                                <Histogram tankerData={tankerData} />
                            </Collapsible>
                            <Collapsible
                                name="Total Product Volumes Arriving"
                                isExpanded={isExpanded}
                            >
                                <TotalsTable tankerTotals={tankerTotals} />
                            </Collapsible>
                        </>
                    )}
                    <br />
                    {tankerData && <TankerDataGrid tankerData={tankerData} />}
                </Grid>
                <Grid item xs={false} sm={1}>
                    {' '}
                </Grid>
            </Grid>
        </MsalAuthenticationTemplate>
    );
};
