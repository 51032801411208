import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { protectedResources } from '../../../config/authConfig';
import { axiosInstance as axios } from '../../../utils/axiosInstance';
import { AppRunTimeContext } from '../../../Context/index';
import { validateInput } from '../../../utils/validation';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    field: {
        padding: '10px',
        alignItems: 'center',
        verticalAlign: 'middle',
        margin: '0px 15px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180
    },
    MuiSelectselectMenu: {
        marginTop: '16px !important'
    }
}));

export const AddSiteModal = ({ AddSite, open, setOpen }) => {
    const [location, setLocation] = useState('');
    const [masterLocation, setMasterLocation] = useState('');
    const [description, setDescription] = useState('');
    const [isMaster, setIsMaster] = useState(false);
    const classes = useStyles();
    const [APImsg, setAPImsg] = useState('');
    const { g_appRunTimeData } = useContext(AppRunTimeContext);

    const customStyles = {
        control: (e) => ({
            ...e,
            width: 200,
            border: 'none',
            borderBottom:
                isMaster && !masterLocation.length ? '2px solid grey' : '2px solid #f44336',
            marginLeft: '22px',
            marginTop: '10px'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition };
        }
    };

    const options = g_appRunTimeData.distinctMasterLocations.sort().map((val) => {
        return { value: val, label: val };
    });

    const addNewSite = async () => {
        if (validateInput(location)) {
            setAPImsg('Enter only a single Location. Please do not enter space, commas or semi-colons');
            return;
        // description may have spaces, commas and semi-colons
        // } else if (validateInput(description)) {
        //     setAPImsg('Description contains invalid inputs');
        //     return;
        } else if (!isMaster && !masterLocation) {
            setAPImsg('Please select the Master Location');
            return;
        }

        setAPImsg('');
        const APIResponse = await axios.post(protectedResources.apiAddNewSite.endpoint, {
            location: location,
            masterLocation: masterLocation,
            description: description,
            isMaster: isMaster ? 'Y' : 'N'
        });
        if (APIResponse.status === 200 && APIResponse.statusText === 'OK') {
            AddSite(location, masterLocation, description, isMaster ? 'Y' : 'N');
            setAPImsg('New Site added');
        } else {
            setAPImsg(APIResponse.data.error);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setAPImsg(null);
        return () => {};
    }, [masterLocation, isMaster]);

    useEffect(() => {
        return () => {
            setLocation('');
            setDescription('');
            setMasterLocation('');
        };
    }, [open]);

    const body = (
        <div className={classes.paper}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.field}
                        label="Location"
                        required
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setLocation(e.target.value)}
                        error={validateInput(location)}
                    />
                </Grid>
                <Grid item xs={12} s={12}>
                    <Select
                        options={options}
                        placeholder="Master Location"
                        styles={customStyles}
                        onChange={(e) => setMasterLocation(e.value)}
                    />
                </Grid>
                <Grid item xs={12} s={12}>
                    <TextField
                        className={classes.field}
                        label="Description"
                        required
                        inputProps={{ maxLength: 60 }}
                        onChange={(e) => setDescription(e.target.value)}
                        error={validateInput(description)}
                    />
                </Grid>
                <Grid item xs={12} s={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isMaster}
                                value={isMaster}
                                onChange={() => setIsMaster((prevVal) => !prevVal)}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Is Master"
                    />
                </Grid>
                <Button
                    className={classes.field}
                    color="primary"
                    variant="contained"
                    onClick={addNewSite}
                >
                    Add
                </Button>
                <Button className={classes.field} variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
            </Grid>
            <br />
            {APImsg}
        </div>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    );
};
