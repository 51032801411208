import { appRoles } from './appRoles';

function getAllRoles(appRoles) {
    var res = [];
    for (const roles in appRoles) res.push(appRoles[roles]);
    return res;
}

export const pageConf = {
    Home: {
        title: 'Tanker ETA',
        roles: getAllRoles(appRoles)
    },
    AdminPanel: {
        title: 'Admin Panel',
        roles: [appRoles.TankerETAAdmin]
    },
    TankerData: {
        title: 'Tanker ETA Data',
        roles: getAllRoles(appRoles)
    },
    ManageSites: {
        title: 'Manage Sites',
        roles: [appRoles.TankerETAAdmin]
    },
    Page404: {
        title: 'Page not Found',
        roles: getAllRoles(appRoles)
    }
};
