import { useState, createContext } from 'react';

//These are global variables
export const AppRunTimeContext = createContext({
    g_userRoles: [],
    g_isUserfetched: false,
    g_siteData: [],
    g_areSitesFetched: false,
    g_isFonterraUser: false,
    g_appRunTimeData: {
        masterLocationHierarchy: new Map(),
        masterLocationDescriptionMap: new Map(),
        userAssignedSiteMap: new Map(),
        distinctMasterLocations: [],
        userPreferences: [],
        allLocations: [],
        associatedSiteCountOriginal: 0,
        associatedSiteCount: 0,
        userEmail: null
    }
});

export const ContextProvider = (props) => {
    const [g_userRoles, g_setUserRoles] = useState([]); //Contains Auth Roles for logged in user
    const [g_isUserfetched, g_setisUserfetched] = useState(false); //Flag to tell if user details are fetched from API
    const [g_isFonterraUser, g_setIsFonterraUser] = useState(false); //Flag to know if Fonterra/External User
    const [g_siteData, g_setSiteData] = useState([]); //Array of all locations of tblSiteMaster
    const [g_areSitesFetched, g_setAreSitesFetched] = useState(false); //Flag to know if Site are fetched from API
    const [g_appRunTimeData, g_SetAppRunTimeData] = useState({
        masterLocationHierarchy: new Map(), //Map of master child hierarchy contains all records
        masterLocationDescriptionMap: new Map(), //Map of site code to description for all sites fetched
        userAssignedSiteMap: new Map(), //Map of all the sites to which user is assigned
        distinctMasterLocations: [], //Array of distinct Master Locations
        userPreferences: [], //Array of user prefered sites for external users
        allLocations: [], //Array of all locations of tblSiteMaster
        associatedSiteCountOriginal: 0, //We wont modify this after initialisation. Will be used only for reference
        associatedSiteCount: 0, //Count of user preferred sites in tblSiteMaster. 0 for default
        userEmail: null //user email address
    });

    return (
        <AppRunTimeContext.Provider
            value={{
                g_userRoles: g_userRoles,
                g_setUserRoles: g_setUserRoles,
                g_isUserfetched: g_isUserfetched,
                g_setisUserfetched: g_setisUserfetched,
                g_isFonterraUser: g_isFonterraUser,
                g_setIsFonterraUser: g_setIsFonterraUser,
                g_appRunTimeData: g_appRunTimeData,
                g_SetAppRunTimeData: g_SetAppRunTimeData,
                g_siteData: g_siteData,
                g_setSiteData: g_setSiteData,
                g_areSitesFetched: g_areSitesFetched,
                g_setAreSitesFetched: g_setAreSitesFetched
            }}
        >
            {props.children}
        </AppRunTimeContext.Provider>
    );
};
