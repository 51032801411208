import { useEffect, useState } from 'react';
import moment from 'moment';
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { generateCSV, generatePDF } from './reportUtils';
import { tableIcons } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .late-tanker.negative': {
            backgroundColor: '#ffc2c2',
            fontWeight: 'bold'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            overflow: 'visible'
        },
        '& .MuiTableCell-alignCenter': {
            border: '1px dotted rgba(0,0,0,0.2)'
        },
        '& .MuiTableCell-root': {
            padding: '5px'
        }
    }
}));

export const TankerDataGrid = ({ tankerData }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const columns = [
        {
            field: 'direction',
            title: 'In/Out',
            align: 'center',
            lookup: { In: 'In', Out: 'Out' },
            render: (rowData) => {
                let icon;
                let color;
                if (rowData.direction == 'In') {
                    icon = faSignInAlt;
                    color = '#002D4E';
                } else if (rowData.direction == 'Out') {
                    icon = faSignOutAlt;
                    color = '#76B82A';
                }
                if (rowData.direction)
                    return <FontAwesomeIcon size="2x" icon={icon} color={color} />;
                else return '';
            },
            cellStyle: { width: '5%' }
        },
        {
            field: 'tankerId',
            align: 'center',
            title: 'Tanker ID',
            cellStyle: { width: '5%' },
            customSort: (a, b) => {
                if (!a.tankerId || !b.tankerId) return 0;
                return a.tankerId.toLowerCase().localeCompare(b.tankerId.toLowerCase());
            }
        },
        {
            field: 'date',
            align: 'center',
            title: 'Date',
            render: (rowData) => {
                if (rowData.date)
                    return moment(rowData.date.substr(0, 10), 'YYYY-MM-DD').format('DD-MMM-YYYY');
                else return 'No Date Found';
            },
            customSort: (a, b) => {
                if (!a.date || !b.date) return 0;
                else
                    return (
                        moment(a.date.substr(0, 10), 'YYYY-MM-DD') -
                        moment(b.date.substr(0, 10), 'YYYY-MM-DD')
                    );
            },
            customFilterAndSearch: (searchTerm, rowData) => {
                searchTerm = searchTerm.toUpperCase();
                if (rowData.date) {
                    return (
                        moment(rowData.date.substr(0, 10), 'YYYY-MM-DD')
                            .format('DD-MMM-YYYY')
                            .toUpperCase()
                            .indexOf(searchTerm.toUpperCase()) != -1
                    );
                } else return 'No Date Found'.toUpperCase().indexOf(searchTerm) != -1;
            }
        },
        {
            field: 'locationFromDescription',
            align: 'center',
            title: 'From (Description)',
            customSort: (a, b) => {
                if (!a.locationFromDescription) return 0;
                if (!b.locationFromDescription) return -1;
                else
                    return a.locationFromDescription
                        .toLowerCase()
                        .localeCompare(b.locationFromDescription.toLowerCase());
            }
        },
        {
            field: 'ETA',
            align: 'center',
            title: 'Pickup ETA',
            render: (rowData) => {
                if (rowData.liveTimePickup)
                    return moment(
                        rowData.liveTimePickup.substr(0, 19),
                        'YYYY-MM-DDThh:mm:ss'
                    ).format('DD-MM-YY HH:mm:ss');
                else return '-';
            },
            customSort: (a, b) => {
                if (!a.liveTimePickup) return 0;
                if (!b.liveTimePickup) return -1;
                else
                    return (
                        moment(a.liveTimePickup.substr(0, 19), 'YYYY-MM-DDThh:mm:ss') -
                        moment(b.liveTimePickup.substr(0, 19), 'YYYY-MM-DDThh:mm:ss')
                    );
            },
            customFilterAndSearch: (searchTerm, rowData) => {
                searchTerm = searchTerm.toUpperCase();
                if (rowData.liveTimePickup) {
                    return (
                        moment(rowData.liveTimePickup.substr(0, 19), 'YYYY-MM-DDThh:mm:ss')
                            .format('DD-MM-YY HH:mm:ss')
                            .toUpperCase()
                            .indexOf(searchTerm.toUpperCase()) != -1
                    );
                } else return '-'.toUpperCase().indexOf(searchTerm) != -1;
            },
            cellStyle: (e, rowData) => {
                if (rowData.liveTimePickup && rowData.plannedTimePickup) {
                    const LiveTime = rowData.liveTimePickup;
                    const plannedTime = rowData.plannedTimePickup;
                    if (moment(LiveTime) > moment(plannedTime).add(30, 'minutes'))
                        return { backgroundColor: '#ffc2c2', fontWeight: 'bold', width: '15%' };
                    if (moment(LiveTime) < moment(plannedTime).subtract(30, 'minutes'))
                        return { backgroundColor: '#c2ffc2', fontWeight: 'bold', width: '15%' };
                }
            }
        },
        {
            field: 'locationToDescription',
            align: 'center',
            title: 'To (Description)',
            customSort: (a, b) => {
                if (!a.locationToDescription) return 0;
                if (!b.locationToDescription) return -1;
                else
                    return a.locationToDescription
                        .toLowerCase()
                        .localeCompare(b.locationToDescription.toLowerCase());
            }
        },
        {
            field: 'ETA',
            align: 'center',
            title: 'Delivery ETA',
            defaultSort: 'asc',
            render: (rowData) => {
                if (rowData.liveTimeDelivery)
                    return moment(
                        rowData.liveTimeDelivery.substr(0, 19),
                        'YYYY-MM-DDThh:mm:ss'
                    ).format('DD-MM-YY HH:mm:ss');
                else return '-';
            },
            customFilterAndSearch: (searchTerm, rowData) => {
                searchTerm = searchTerm.toUpperCase();
                if (rowData.liveTimeDelivery) {
                    return (
                        moment(rowData.liveTimeDelivery.substr(0, 19), 'YYYY-MM-DDThh:mm:ss')
                            .format('DD-MM-YY HH:mm:ss')
                            .toUpperCase()
                            .indexOf(searchTerm.toUpperCase()) != -1
                    );
                } else return '-'.toUpperCase().indexOf(searchTerm) != -1;
            },
            customSort: (a, b) => {
                if (!a.liveTimeDelivery) return 0;
                if (!b.liveTimeDelivery) return -1;
                else
                    return (
                        moment(a.liveTimeDelivery.substr(0, 19), 'YYYY-MM-DDThh:mm:ss') -
                        moment(b.liveTimeDelivery.substr(0, 19), 'YYYY-MM-DDThh:mm:ss')
                    );
            },
            cellStyle: (e, rowData) => {
                if (rowData.liveTimeDelivery && rowData.plannedTimeDelivery) {
                    const LiveTime = rowData.liveTimeDelivery;
                    const plannedTime = rowData.plannedTimeDelivery;
                    if (moment(LiveTime) > moment(plannedTime).add(30, 'minutes'))
                        return { backgroundColor: '#ffc2c2', fontWeight: 'bold', width: '15%' };
                    if (moment(LiveTime) < moment(plannedTime).subtract(30, 'minutes'))
                        return { backgroundColor: '#c2ffc2', fontWeight: 'bold', width: '15%' };
                }
            }
        },
        {
            field: 'shift',
            align: 'center',
            title: 'Shift',
            lookup: { Day: 'Day', Night: 'Night' },
            cellStyle: { width: '5%' }
        },
        {
            field: 'simpleProduct',
            align: 'center',
            title: 'Product',
            cellStyle: { width: '10%' },
            customSort: (a, b) => {
                if (!a.simpleProduct || !b.simpleProduct) return 0;
                return a.simpleProduct.toLowerCase().localeCompare(b.simpleProduct.toLowerCase());
            }
        },
        {
            field: 'volume',
            align: 'center',
            title: 'Volume',
            render: (rowData) => {
                return rowData.volume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            cellStyle: { width: '5%' }
        },
        {
            field: 'id',
            title: 'ID',
            hidden: true
        }
    ];

    useEffect(() => {
        setData(tankerData || []);
        return () => {};
    }, [tankerData]);

    return (
        <div className={classes.root}>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={data}
                options={{
                    headerStyle: {
                        backgroundColor: '#8DC8E8',
                        color: '#000',
                        borderLeft: '1px solid #f0f0f0',
                        borderRight: '1px solid #f0f0f0'
                    },
                    filtering: true,
                    sorting: true,
                    search: true,
                    exportButton: true,
                    exportAllData: true,
                    pageSizeOptions: [5, 10, 20, 50, 100, data.length],
                    pageSize: 50,
                    exportPdf: (columns, data) => {
                        generatePDF(columns, data);
                    },
                    exportCsv: (columns, data) => {
                        generateCSV(columns, data);
                    }
                }}
            />
        </div>
    );
};
