import { useEffect, useState, useContext } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { SiteTable } from './components/SiteTable';
import { loginRequest, protectedResources } from '../../config/authConfig';
import { axiosInstance as axios } from '../../utils/axiosInstance';
import { AppRunTimeContext } from '../../Context/index';
import { getLocationHierarchy } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

export const ManageSite = (props) => {
    document.title = props.title;
    const classes = useStyles();
    const authRequest = { ...loginRequest };
    const {
        g_appRunTimeData,
        g_SetAppRunTimeData,
        g_siteData,
        g_setSiteData,
        g_areSitesFetched,
        g_setAreSitesFetched
    } = useContext(AppRunTimeContext);

    const ParentDeleteSite = (location) => {
        let deletedSiteData = g_siteData;
        deletedSiteData = deletedSiteData.filter((row) => {
            return row.location !== location;
        });
        g_setSiteData(deletedSiteData);
    };

    useEffect(async () => {
        if (!g_areSitesFetched) {
            const APIResponse = await axios.get(protectedResources.apiGetAllSites.endpoint);
            if (APIResponse.status === 200 && APIResponse.statusText === 'OK') {
                g_setSiteData(APIResponse.data.records);
                g_setAreSitesFetched(true);
                g_SetAppRunTimeData({
                    ...g_appRunTimeData,
                    masterLocationHierarchy: getLocationHierarchy(APIResponse.data.records)
                });
            }
        }
        return () => {};
    }, []);

    return (
        <MsalAuthenticationTemplate authenticationRequest={authRequest}>
            <div className={classes.root}>
                <Grid container direction="row">
                    <Grid item sm={1} xs={false}></Grid>
                    <Grid item sm={10} xs={12}>
                        {g_siteData && (
                            <SiteTable siteData={g_siteData} deleteSite={ParentDeleteSite} />
                        )}
                    </Grid>
                    <Grid item sm={1} xs={false}></Grid>
                </Grid>
            </div>
        </MsalAuthenticationTemplate>
    );
};
