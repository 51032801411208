import { useState, useContext, useEffect } from 'react';
import { axiosInstance as axios } from '../../../utils/axiosInstance';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { AppRunTimeContext } from '../../../Context/index';
import { protectedResources } from '../../../config/authConfig';

const directionOptions = [
    { value: 'In', label: 'In' },
    { value: 'Out', label: 'Out' }
];

const shiftOptions = [
    { value: 'D', label: 'Day' },
    { value: 'N', label: 'Night' }
];

const getLocationDropDown = (g_appRunTimeData, locationMap) => {
    let locations = [];
    g_appRunTimeData.allSites.sort().map((val) => {
        let header = {};
        //Find the corresponding master location for site from  map
        //and push/update to locations array.
        const masterLocation = locationMap.get(val.split(' -- ')[1]);
        const isPresent = locations.findIndex((row) => row.label == masterLocation);
        if (isPresent === -1) {
            header['label'] = locationMap.get(val.split(' -- ')[1]);
            header['options'] = [{ value: val, label: val }];
            locations.push(header);
        } else locations[isPresent]['options'].push({ value: val, label: val });

        return { value: val, label: val };
    });

    //Sort Master Locations
    locations = locations.sort((a, b) => a.label.localeCompare(b.label));

    //Sort child locations in order so master location is the first child
    //followed by other child locations as pr their site code
    for (const location of locations) {
        location.options.sort((a, b) => {
            const A = a.label.split(' -- ')[1];
            const B = b.label.split(' -- ')[1];
            if (isNaN(parseInt(A[0]))) return -1;
            else if (isNaN(parseInt(B[0]))) return 1;
            else return a.label.split(' -- ')[1].localeCompare(b.label.split(' -- ')[1]);
        });
    }
    locations.unshift({ label: '', options: [{ value: '', label: 'Default Sites' }] });
    return locations;
};

const useStyles = makeStyles((theme) => ({
    searchfield: {
        alignItems: 'center',
        verticalAlign: 'middle',
        margin: '0px 10px'
    }
}));

const customStyles = (selectWidth = '250px', listWidth = '250px') => {
    return {
        menu: (provided) => ({ ...provided, zIndex: 9999, width: listWidth }),
        control: (e) => ({
            ...e,
            width: selectWidth,
            marginTop: '10px',
            border: 'none',
            borderBottom: '1px solid grey'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    };
};

export const TankerDataFilters = ({ setTankerData, setIsFetchingData, setTankerTotals }) => {
    const classes = useStyles();
    const [site, setSite] = useState('');
    const [direction, setDirection] = useState([]);
    const [shift, setShift] = useState([]);
    const { g_appRunTimeData, g_SetAppRunTimeData } = useContext(AppRunTimeContext);
    const locationMap = g_appRunTimeData.masterLocationHierarchy;
    const locations = getLocationDropDown(g_appRunTimeData, locationMap);

    const fetchTotalsData = async (site) => {
        let totalsURL = `${protectedResources.apiTankerTotals.endpoint}?`;
        if (site) totalsURL += site ? `&site=${site.split(' -- ')[1]}` : '';
        const totalAPIResponse = await axios.get(totalsURL);
        if (totalAPIResponse.status === 200 && totalAPIResponse.statusText === 'OK')
            setTankerTotals(totalAPIResponse.data.records);
        else alert(totalAPIResponse.data.error);
    };

    const fetchETAData = async (e) => {
        const button = e.target.innerText; // FILTER | REFRESH
        setIsFetchingData(true);
        let URL = `${protectedResources.apiTankerData.endpoint}?`;

        //Set the location's master Site to localStorage so it
        //can be used during filtering on totals
        const storage = JSON.stringify({
            masterLocation: locationMap.get(site.split(' -- ')[1]),
            childLocation: site.split(' -- ')[1],
            childLocationName: site.split(' -- ')[0]
        });
        localStorage.setItem('ETASearchLocation', storage);

        //Send URL params when site is selected
        if (site) {
            URL += site ? `&site=${site.split(' -- ')[1]}` : '';
            URL += shift ? shift.map((row) => '&shift=' + row).join('') : '';
            URL += direction ? direction.map((row) => '&direction=' + row).join('') : '';
        }

        const APIResponse = await axios.get(URL);

        setIsFetchingData(false);
        if (APIResponse.status === 200 && APIResponse.statusText === 'OK') {
            setTankerData(APIResponse.data.records);
            g_SetAppRunTimeData({
                ...g_appRunTimeData,
                associatedSiteCount: site ? 1 : g_appRunTimeData.associatedSiteCountOriginal
            });
        } else alert(APIResponse.data.error);

        //Now we call the totals API
        fetchTotalsData(site);
    };

    useEffect(() => {
        return () => {
            localStorage.removeItem('ETASearchLocation');
        };
    }, []);

    return (
        <>
            <Grid direction="row" style={{ display: 'inline-flex' }}>
                <Select
                    options={locations}
                    className={classes.searchfield}
                    placeholder="Site"
                    onChange={(e) => setSite(e.value)}
                    styles={customStyles('350px', '450px')}
                />
                <Select
                    isMulti
                    name="direction"
                    isDisabled={site.length == 0}
                    options={directionOptions}
                    placeholder="In/Out"
                    className={classes.searchfield}
                    onChange={(e) => setDirection(e.map((r) => r.value))}
                    styles={customStyles('250px', '150px')}
                />
                <Select
                    isMulti
                    name="shift"
                    isDisabled={site.length == 0}
                    options={shiftOptions}
                    placeholder="Shift"
                    className={classes.searchfield}
                    onChange={(e) => setShift(e.map((r) => r.value))}
                    styles={customStyles('250px', '150px')}
                />
                <Button
                    className={classes.searchfield}
                    color="primary"
                    label="Search"
                    variant="contained"
                    onClick={fetchETAData}
                    // disabled={site.length == 0}
                >
                    Search/Refresh
                </Button>
                {/* <Button
                    className={classes.searchfield}
                    color="primary"
                    label="refresh"
                    variant="contained"
                    onClick={fetchETAData}
                    disabled={disableRefresh}
                >
                    Refresh
                </Button> */}
                <br />
            </Grid>
        </>
    );
};
