import { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AppRunTimeContext } from '../../../Context/index';

const Row = ({ row, isMaster }) => {
    const [open, setOpen] = useState(false);

    const getCollapsible = (row) => {
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Location To</TableCell>
                                    <TableCell>Expected Volume</TableCell>
                                    <TableCell>Remaining Volume</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.child.map((childRow) => (
                                    <TableRow key={childRow.key}>
                                        <TableCell>{childRow.locationTo}</TableCell>
                                        <TableCell>{childRow.volume}</TableCell>
                                        <TableCell>{childRow.remvolume}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        );
    };

    return (
        <>
            <TableRow style={{ backgroundColor: '#efefef' }}>
                <TableCell>
                    <div hidden={!isMaster}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </div>
                </TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.shift}</TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>{row.volume}</TableCell>
                <TableCell>{row.remvolume}</TableCell>
            </TableRow>
            {/* Show Collapsible only when site filtered on is master */}
            <TableRow>{isMaster && getCollapsible(row)}</TableRow>
        </>
    );
};

//Returns the aggregate for volumes based on different product/shift/master locations
const getNewTotalVolumes = (tankerTotals, locationMap, isMaster, descriptionMap) => {
    let result = [];

    //Unique keys for mapping
    let keyP = 0;
    let keyC = tankerTotals.length + 1;

    //Looping over the totals records and creating a nexted structure
    for (const data of tankerTotals) {
        let parentRow = {};
        parentRow['location'] = isMaster
            ? locationMap.get(data.locationTo)
            : descriptionMap.get(data.locationTo);
        parentRow['shift'] = data.shift == 'D' ? 'Day' : 'Night';
        parentRow['product'] = data.simpleProduct;
        parentRow['volume'] = data.volume;
        parentRow['remvolume'] = data.remainingVolume;
        parentRow['date'] = moment(data.date.substr(0, 10), 'YYYY-MM-DD').format('DD-MMM');
        parentRow['child'] = [];
        parentRow['key'] = keyP++;

        const childRow = {
            key: keyC++,
            locationTo: descriptionMap.get(data.locationTo),
            volume: data.volume,
            remvolume: data.remainingVolume
        };

        //Checking if parent row exists
        if (
            parentRow['location'] &&
            parentRow['shift'] &&
            parentRow['product'] &&
            parentRow['date'] &&
            parentRow['volume']
        ) {
            const isParentPresentIdx = result.findIndex(
                (obj) =>
                    obj.location == parentRow.location &&
                    obj.shift == parentRow.shift &&
                    obj.date == parentRow.date &&
                    obj.product == parentRow.product
            );

            //If parent row not present, insert parent row along with child row
            //else update volume totals for child and parent row
            if (isParentPresentIdx === -1) {
                parentRow.child.push(childRow);
                result.push(parentRow);
            } else {
                result[isParentPresentIdx]['volume'] += data.volume;
                result[isParentPresentIdx]['remvolume'] += data.remainingVolume;
                const isChildPresentIdx = result[isParentPresentIdx]['child'].findIndex(
                    (obj) => obj.locationTo == descriptionMap.get(data.locationTo)
                );
                if (isChildPresentIdx === -1) result[isParentPresentIdx]['child'].push(childRow);
                else {
                    result[isParentPresentIdx]['child'][isChildPresentIdx].volume +=
                        childRow.volume;
                    result[isParentPresentIdx]['child'][isChildPresentIdx].remvolume +=
                        childRow.remvolume;
                }
            }
        }
    }

    return result;
};

export const TotalsTable = ({ tankerTotals }) => {
    tankerTotals = tankerTotals || [];
    const { g_appRunTimeData } = useContext(AppRunTimeContext);
    const locationMap = g_appRunTimeData.masterLocationHierarchy;
    const descriptionMap = g_appRunTimeData.masterLocationDescriptionMap;
    const filterData = localStorage.getItem('ETASearchLocation')
        ? JSON.parse(localStorage.getItem('ETASearchLocation'))
        : null;
    const isMaster = filterData ? filterData.masterLocation == filterData.childLocation : true;
    const aggregateData = getNewTotalVolumes(
        tankerTotals,
        locationMap,
        isMaster,
        descriptionMap
    ).sort((a, b) => {
        if (a && b) return a.location.toLowerCase().localeCompare(b.location.toLowerCase());
        return 0;
    });

    return (
        <TableContainer component={Paper} style={{ margin: '25px 0px' }}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow style={{ backgroundColor: '#8DC8E8' }}>
                        <TableCell />
                        <TableCell>Date</TableCell>
                        <TableCell>Location To</TableCell>
                        <TableCell>Shift</TableCell>
                        <TableCell>Product</TableCell>
                        <TableCell>Expected Volume</TableCell>
                        <TableCell>Remaining Volume</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {aggregateData.map((row) => {
                        return <Row key={row.key} row={row} isMaster={isMaster} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
