import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './config/authConfig';
import { loginRequest } from './config/authConfig';
import './styles/index.css';
import { ContextProvider } from './Context/index';

export const msalInstance = new PublicClientApplication(msalConfig);
const accounts = msalInstance.getAllAccounts();

export const acquireAccessToken = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount();

    if (!activeAccount && accounts.length === 0) {
        alert('Please login with your Fonterra Domain. Refreshing Page');
        window.location.reload();
    }

    const authResult = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: activeAccount || accounts[0]
    });

    return authResult.accessToken;
};

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account)
        msalInstance.setActiveAccount(event.payload.account);

    if (event.eventType === EventType.LOGIN_FAILURE) console.log(JSON.stringify(event));
});

ReactDOM.render(
    <React.StrictMode>
        <ContextProvider>
            <App instance={msalInstance} />
        </ContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
