import { loginRequest } from '../../config/authConfig';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '150px'
    },
    genericText: {
        fontSize: 16,
        color: '#000000',
        fontFamily: 'Recoleta'
    },
    errorText: {
        fontSize: 14,
        color: '#A3A3A3',
        fontFamily: 'Kievit'
    }
}));

export const pageNotFound = (props) => {
    document.title = 'Error - Not Found';
    const classes = useStyles();
    const authRequest = { ...loginRequest };
    const error = props.location ? (props.location.state ? props.location.state.error : '') : '';
    const text = "Oops, we're unable to figure out which tanker runs are relevant to you";

    return (
        <MsalAuthenticationTemplate authenticationRequest={authRequest}>
            <h4>
                <center className={classes.root}>
                    <img src="./error.png" />
                    <p className={classes.genericText}>{text}</p>
                    <p className={classes.errorText}>{error}</p>
                </center>
            </h4>
        </MsalAuthenticationTemplate>
    );
};
