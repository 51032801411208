import { useEffect, useState, useContext } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import { axiosInstance as axios } from '../../../utils/axiosInstance';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { protectedResources } from '../../../config/authConfig';
import { AddUserModal } from './AddUserModal';
import { AppRunTimeContext } from '../../../Context/index';
import { tableIcons } from '../../../components/Icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .datagrid-header': {
            backgroundColor: '#8DC8E8'
        },
        '& .late-tanker.negative': {
            backgroundColor: '#ffc2c2',
            fontWeight: 'bold'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            overflow: 'visible'
        },
        '& .MuiDataGrid-cell': {
            border: '1px dotted rgba(0,0,0,0.1)'
        }
    },
    textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        float: 'right',
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5)
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    }
}));

export const UserDataGrid = (props) => {
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const { g_appRunTimeData, g_SetAppRunTimeData } = useContext(AppRunTimeContext);
    const classes = useStyles();

    const columns = [
        {
            field: 'id',
            title: 'ID',
            hidden: true
        },
        {
            field: 'user',
            title: 'User Email',
            editable: 'never',
            filterPlaceholder: 'User'
        },
        {
            field: 'location',
            title: 'Location',
            lookup: g_appRunTimeData.distinctMasterLocations
                .sort((a, b) => a - b)
                .reduce((a, v) => ({ ...a, [v]: v }), {}),
            filterPlaceholder: 'Location'
        }
    ];

    const _delete = (rowData) => {
        if (confirm('This will delete the assignment, do you wish to continue?'))
            return deleteUser(rowData.id, rowData.user, rowData.location);
    };

    useEffect(() => {
        if (props.userData) setData(props.userData);
        return () => {};
    }, [props.userData]);

    const deleteUser = async (userID, deletedEmail, deletedLocation) => {
        let APIResponse = await axios.delete(protectedResources.apiDeleteUser.endpoint, {
            data: { id: userID }
        });
        if (APIResponse.status === 200 && APIResponse.statusText === 'OK') {
            let newAssignedSiteCount = 0;
            let newUserAssociatedSites = g_appRunTimeData.userAssignedSiteMap;
            //Add user from App runtime
            props.deleteUser(userID);
            //If current user is removed, decrease associatedSiteCount
            if (g_appRunTimeData.userEmail.toUpperCase() === deletedEmail.toUpperCase()) {
                //Iterate over the map and delete child sites from user preferences during runtime
                for (const [key, value] of g_appRunTimeData.masterLocationHierarchy.entries()) {
                    if (value == deletedLocation) {
                        newAssignedSiteCount++;
                        newUserAssociatedSites.delete(key);
                    }
                }
                g_SetAppRunTimeData({
                    ...g_appRunTimeData,
                    associatedSiteCount:
                        g_appRunTimeData.associatedSiteCount - newAssignedSiteCount,
                    associatedSiteCountOriginal:
                        g_appRunTimeData.associatedSiteCountOriginal - newAssignedSiteCount,
                    userAssignedSiteMap: newUserAssociatedSites
                });
            }
        } else alert(responseData.error);
    };

    const handleRowUpdate = (newData, oldData, resolve) => {
        axios
            .put(protectedResources.apiUpdateUser.endpoint, {
                id: newData.id,
                site: newData.location,
                username: newData.user
            })
            .then((resp) => {
                //If user is updating own profile, update app runtime data
                if (g_appRunTimeData.userEmail.toUpperCase() === oldData.user.toUpperCase()) {
                    let newAssignedSiteCount = 0;
                    let newUserAssociatedSites = g_appRunTimeData.userAssignedSiteMap;

                    for (const [key, value] of g_appRunTimeData.masterLocationHierarchy.entries()) {
                        if (value == newData.location) {
                            newAssignedSiteCount++;
                            newUserAssociatedSites.set(key, 1);
                        }
                        if (value == oldData.location) {
                            newAssignedSiteCount--;
                            newUserAssociatedSites.delete(key);
                        }
                    }

                    g_SetAppRunTimeData({
                        ...g_appRunTimeData,
                        associatedSiteCount:
                            g_appRunTimeData.associatedSiteCount + newAssignedSiteCount,
                        associatedSiteCountOriginal:
                            g_appRunTimeData.associatedSiteCountOriginal + newAssignedSiteCount,
                        userAssignedSiteMap: newUserAssociatedSites
                    });
                }
                oldData.location = newData.location;
                resolve();
            })
            .catch((err) => {
                resolve();
            });
    };

    const AddUser = (id, user, location) => {
        if (data) setData([...data, { id: id, user: user, location: location }]);
        else setData([{ id: id, user: user, location: location }]);
    };

    return (
        <>
            <div className={classes.root}>
                <MaterialTable
                    icons={tableIcons}
                    title=""
                    columns={columns}
                    data={data}
                    options={{
                        headerStyle: {
                            backgroundColor: '#8DC8E8',
                            color: '#000',
                            borderLeft: '1px solid #f0f0f0',
                            borderRight: '1px solid #f0f0f0'
                        },
                        sorting: true,
                        search: true,
                        exportButton: true,
                        exportAllData: true,
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        pageSize: data.length / 3 < 10 ? 10 : Math.ceil(data.length / 4 / 5) * 5
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve);
                            })
                    }}
                    actions={[
                        {
                            icon: DeleteIcon,
                            tooltip: 'Delete',
                            onClick: (event, rowData) => _delete(rowData)
                        },
                        {
                            icon: AddBox,
                            tooltip: 'Add Site',
                            position: 'toolbar',
                            onClick: (event, rowData) => {
                                setOpen(true);
                            }
                        }
                    ]}
                />
                <AddUserModal open={open} setOpen={setOpen} AddUser={AddUser} />
            </div>
        </>
    );
};
