import { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const Collapsible = ({ name, children, isExpanded }) => {
    const [open, setOpen] = useState(isExpanded);

    useEffect(() => {
        if (isExpanded != 'blank') setOpen(isExpanded);
    }, [isExpanded]);

    return (
        <>
            <div
                style={{ backgroundColor: '#cccccc', cursor: 'pointer' }}
                onClick={() => setOpen(open == 'expanded' ? 'collapsed' : 'expanded')}
            >
                <TableCell style={{ float: 'right', height: '5px', border: 'none' }}>
                    <IconButton aria-label="expand row" size="small">
                        {open == 'expanded' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{name}</TableCell>
            </div>
            <Collapse in={open == 'expanded'} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );
};
