import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { UserDataGrid } from './components/UserDataGrid';
import { AdminDataFilters } from './components/AdminDataFilters';
import { loginRequest } from '../../config/authConfig';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));

export const AdminPanel = (props) => {
    document.title = props.title;
    const classes = useStyles();
    const [allUserData, setAllUserData] = useState(null);
    const [isFetchingData, setIsFetchingData] = useState(false);

    const authRequest = {
        ...loginRequest
    };

    const ParentDeleteUser = (id) => {
        let deletedUserData = allUserData;
        deletedUserData = deletedUserData.filter((row) => {
            return row.id !== id;
        });
        setAllUserData(deletedUserData);
    };

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <MsalAuthenticationTemplate authenticationRequest={authRequest}>
            <div className={classes.root}>
                <Grid container direction="row">
                    <Grid item sm={1} xs={false}>
                        {' '}
                    </Grid>
                    <Grid item sm={10} xs={12}>
                        <Grid container direction="row">
                            <AdminDataFilters
                                setAllUserData={setAllUserData}
                                setIsFetchingData={setIsFetchingData}
                            />
                        </Grid>
                        <br />
                        {isFetchingData && <LinearProgress />}
                        <UserDataGrid userData={allUserData} deleteUser={ParentDeleteUser} />
                    </Grid>
                    <Grid item sm={1} xs={false}>
                        {' '}
                    </Grid>
                </Grid>
            </div>
        </MsalAuthenticationTemplate>
    );
};
