import { useEffect, useState, useContext } from 'react';
import { axiosInstance as axios } from '../utils/axiosInstance';
import { MsalAuthenticationTemplate, useMsal, useAccount } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest, protectedResources } from '../config/authConfig';
import { AppRunTimeContext } from '../Context';
import { getLocationHierarchy, getLocationDescription, getUserAssignedSites } from '../utils/utils';

export const Home = (props) => {
    document.title = 'Tanker ETA';
    const {
        g_userRoles,
        g_setUserRoles,
        g_isUserfetched,
        g_setisUserfetched,
        g_appRunTimeData,
        g_setIsFonterraUser,
        g_setSiteData,
        g_setAreSitesFetched,
        g_SetAppRunTimeData
    } = useContext(AppRunTimeContext);
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    async function getUserDetails() {
        if (account && inProgress === 'none' && !g_isUserfetched)
            return await axios.get(protectedResources.apiUserData.endpoint);
    }

    async function getAllSites() {
        if (account && inProgress === 'none' && !g_appRunTimeData.distinctMasterLocations.length)
            return await axios.get(protectedResources.apiGetAllSites.endpoint);
    }

    async function getAssociatedSites() {
        if (account && inProgress === 'none' && !g_isUserfetched)
            return await axios.get(protectedResources.apiGetUserAssociatedSites.endpoint);
    }

    useEffect(async () => {
        const user = await getUserDetails();
        if (user && user.status === 200 && user.statusText === 'OK') {
            const isFonterraUser = account.username.split('@')[1] === 'fonterra.com';
            g_setisUserfetched(true);
            g_setIsFonterraUser(isFonterraUser);
            if (user.data.roles.length) {
                //Populating runtime data/select lists/sites one-time from API
                const allSiteList = await getAllSites();
                const associatedSitesList = await getAssociatedSites();
                const distinctMasterLocationsAPI = isFonterraUser
                    ? allSiteList.data
                    : associatedSitesList.data;
                g_SetAppRunTimeData({
                    ...g_appRunTimeData,
                    distinctMasterLocations: allSiteList.data.records
                        .filter((row) => row.isMaster == 'Y')
                        .map((row) => row.location),
                    allSites: distinctMasterLocationsAPI.records.map(
                        (row) => row.description + ' -- ' + row.location
                    ),
                    masterLocationHierarchy: getLocationHierarchy(allSiteList.data.records),
                    masterLocationDescriptionMap: getLocationDescription(allSiteList.data.records),
                    userAssignedSiteMap: getUserAssignedSites(associatedSitesList.data.records),
                    associatedSiteCount: associatedSitesList.data.rowCount,
                    associatedSiteCountOriginal: associatedSitesList.data.rowCount,
                    userEmail: account.username
                });
                g_setSiteData(allSiteList.data.records);
                g_setAreSitesFetched(true);
                g_setUserRoles(user.data.roles);
                props.history.push('/data');
            } else {
                g_setUserRoles([]);
                props.history.push({
                    pathname: '/error',
                    state: { error: 'No Auth Roles Found. Please Contact Administrator' }
                });
                return;
            }
        }
    }, [account, inProgress, instance, g_userRoles]);

    const authRequest = { ...loginRequest };
    return (
        <>
            {/* This component is required to kick authentication and initiate AD login */}
            <MsalAuthenticationTemplate
                authenticationRequest={authRequest}
                interactionType={InteractionType.Redirect}
            />
            <h2>
                <center>HOME - {process.env.REACT_APP_APP_ENVIRONMENT}</center>
            </h2>
        </>
    );
};
