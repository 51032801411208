import { NavigationBar } from './NavigationBar';

export const PageLayout = (props) => {
    return (
        <>
            <NavigationBar />
            <br />
            {props.children}
            <br />
        </>
    );
};
