import { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { protectedResources } from '../../../config/authConfig';
import { isMobile } from '../../../utils/utils';
import { validateInput } from '../../../utils/validation';
import { axiosInstance as axios } from '../../../utils/axiosInstance';
import { AppRunTimeContext } from '../../../Context/index';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    field: {
        padding: '10px',
        alignItems: 'center',
        verticalAlign: 'middle',
        margin: '0px 10px'
    }
}));

export const AddUserModal = ({ AddUser, open, setOpen }) => {
    const classes = useStyles();
    const [newUser, setNewUser] = useState('');
    const [newsite, setNewSite] = useState('');
    const [APImsg, setAPImsg] = useState('');
    const { g_appRunTimeData, g_SetAppRunTimeData } = useContext(AppRunTimeContext);

    const addNewUser = async () => {
        if (!newUser || !newsite) {
            setAPImsg('Please fill in all the details.');
            return;
        }

        setAPImsg('');
        const APIResponse = await axios.post(protectedResources.apiPostNewUser.endpoint, {
            user: newUser,
            site: newsite
        });
        if (APIResponse.status === 200 && APIResponse.statusText === 'OK') {
            let newAssignedSiteCount = 0;
            let newUserAssociatedSites = g_appRunTimeData.userAssignedSiteMap;
            setNewUser('');
            setNewSite('');
            setAPImsg('User added to site');
            //Add user to App runtime
            AddUser(APIResponse.data.newID, newUser, newsite);
            //If current user is removed, increase associatedSiteCount
            if (g_appRunTimeData.userEmail.toUpperCase() === newUser.toUpperCase()) {
                //Iterate over the map and set child sites into user preferences during runtime
                for (const [key, value] of g_appRunTimeData.masterLocationHierarchy.entries()) {
                    if (value == newsite) {
                        newAssignedSiteCount++;
                        newUserAssociatedSites.set(key, 1);
                    }
                }
                g_SetAppRunTimeData({
                    ...g_appRunTimeData,
                    associatedSiteCount:
                        g_appRunTimeData.associatedSiteCount + newAssignedSiteCount,
                    associatedSiteCountOriginal:
                        g_appRunTimeData.associatedSiteCountOriginal + newAssignedSiteCount,
                    userAssignedSiteMap: newUserAssociatedSites
                });
            }
        } else setAPImsg(APIResponse.data.error);
    };

    const customStyles = {
        control: (e) => ({
            ...e,
            width: 200,
            marginTop: '10px',
            marginLeft: '15px',
            border: 'none',
            borderBottom: newsite.length ? '2px solid grey' : '3px solid #f44336'
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition };
        }
    };

    const options = g_appRunTimeData.distinctMasterLocations.sort().map((val) => {
        return { value: val, label: val };
    });

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setAPImsg('');
        return () => {
            setNewUser('');
            setNewSite('');
        };
    }, [open]);

    const body = (
        <div className={classes.paper}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <TextField
                        className={classes.field}
                        id="username"
                        onChange={(e) => setNewUser(e.target.value)}
                        label="User Email"
                        value={newUser}
                        required
                        error={validateInput(newUser)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        options={options}
                        placeholder="Master Location"
                        styles={customStyles}
                        onChange={(e) => setNewSite(e.value)}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Button
                        className={classes.field}
                        color="primary"
                        variant="contained"
                        onClick={addNewUser}
                    >
                        Add
                    </Button>
                    <Button className={classes.field} variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <br />
            {APImsg}
        </div>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    );
};
