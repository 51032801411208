import moment from 'moment';
import { CsvBuilder } from 'filefy';

//Custom Time/Date formatter for PDF Download
export const generatePDF = (columns, data) => {
    const jsPDF = typeof window !== 'undefined' ? require('jspdf').jsPDF : null;
    data = getFormattedReportData(data).map((row) => Object.values(row));

    if (jsPDF !== null) {
        columns.pop(); // remove last ID column
        let content = {
            startY: 50,
            head: [columns.map((columnDef) => columnDef.title)],
            body: data
        };

        const unit = 'pt';
        const size = 'A4';
        const orientation = 'landscape';

        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        doc.text('ETA Report', 40, 40);
        doc.autoTable(content);
        doc.save('ETA Report' + '.pdf');
    }
};

//Custom Time/Date formatter for CSV Download
export const generateCSV = (columns, data) => {
    const fileName = 'ETA Report';
    const builder = new CsvBuilder(fileName + '.csv');
    data = getFormattedReportData(data).map((row) => Object.values(row));
    columns.pop(); // remove last ID column

    builder
        .setDelimeter(',')
        .setColumns(columns.map((columnDef) => columnDef.title))
        .addRows(data)
        .exportFile();
};

//Returns formatted data to write onto PDF/CSV
//Any data transformations may done here
const getFormattedReportData = (data) => {
    data = data.map((row) => {
        let newObj = [];
        newObj['direction'] = row.direction;
        newObj['tankerId'] = row.tankerId;
        newObj['date'] = row.date
            ? moment(row.date.substr(0, 10), 'YYYY-MM-DD').format('DD-MMM-YYYY')
            : 'No Date Found';
        newObj['from'] = row.locationFromDescription;
        newObj['pickupETA'] = row.liveTimePickup
            ? moment(row.liveTimePickup.substr(0, 19), 'YYYY-MM-DDThh:mm:ss').format(
                  'DD-MM-YY HH:mm:ss'
              )
            : '-';
        newObj['to'] = row.locationToDescription;
        newObj['deliveryETA'] = row.liveTimeDelivery
            ? moment(row.liveTimeDelivery.substr(0, 19), 'YYYY-MM-DDThh:mm:ss').format(
                  'DD-MM-YY HH:mm:ss'
              )
            : '-';
        newObj['shift'] = row.shift;
        newObj['simpleProduct'] = row.simpleProduct;
        newObj['volume'] = row.volume;
        return newObj;
    });
    return data;
};
